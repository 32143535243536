import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ConfigData from "../../config.json";
import noImage from "../../assets/images/certificate_default.jpg";
import "./DownloadCertificate.css";

const DownloadCertificate = () => {
  const { candidateId, certId } = useParams();
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const fetchFirst = fetch(
          `${ConfigData.API_URL}/candidates/${candidateId}/certificates/${certId}`
        );
        const fetchSecond = fetch(
          `${ConfigData.API_URL}/mhrsd/candidates/${candidateId}/certificates/${certId}`
        );

        const results = await Promise.allSettled([fetchFirst, fetchSecond]);

        // Check which promise succeeded
        const successfulResponse = results.find(
          (result) => result.status === "fulfilled" && result.value.ok
        );

        if (successfulResponse) {
          const data = await successfulResponse.value.json();
          setCertificate(data);
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCertificate();
  }, [certId, candidateId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!certificate) {
    return <div>No certificate found</div>;
  }

  return (
    <div className="main">
      <div className="card" style={{ width: 500, margin: "auto" }}>
        <div className="row no-gutters">
          <div
            onClick={() => {
              window
                .open(
                  `${
                    certificate.imageUrl ||
                    certificate["certificates"]["v1"]["certificatePDFUrl"]
                  }${ConfigData.AZURE_BLOB_SAS_URL}`,
                  "_blank"
                )
                .focus();
            }}
            className="col-sm-5 my-awesome-div transparent-button"
            style={{ margin: "auto" }}
          >
            {/* Use a default image if no certificate image URL is provided */}
            <object
              data={`${
                certificate.imageUrl ||
                certificate["certificates"]["v1"]["certificatePDFUrl"]
              }${ConfigData.AZURE_BLOB_SAS_URL}`}
              width="100%"
              height="202px"
              style={{
                pointerEvents: "none",
                border: "none",
              }}
              type="application/pdf"
            >
              {/* <p>
                Please download the certificate from{" "}
                <a
                  href={`${certificate.imageUrl}${ConfigData.AZURE_BLOB_SAS_URL}`}
                >
                  here
                </a>
              </p> */}
              <button
                style={{
                  color: "white",
                  background: "#0076be",
                  width: "auto",
                  padding: "2px 4px",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                  border: "none",
                  cursor: "pointer",
                  display: "inline-block",
                }}
                onClick={() => {
                  window
                    .open(
                      `${
                        certificate.imageUrl ||
                        certificate["certificates"]["v1"]["certificatePDFUrl"]
                      }${ConfigData.AZURE_BLOB_SAS_URL}`,
                      "_blank"
                    )
                    .focus();
                }}
              >
                Get Certificate
              </button>
            </object>
          </div>
          <div className="col-sm-7">
            <div className="card-body">
              <h5 className="card-title">{certificate.name}</h5>
              <p className="card-text">Student ID: {certificate.studentId}</p>
              <p className="card-text">
                Program Name: {certificate.subject || certificate.course}
              </p>
              <p className="card-text">
                Date of completion: {certificate.startDate}
              </p>
              <p className="card-text">
                Date of certificate issued: {certificate.endDate}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadCertificate;
